import React from "react"

const NotFoundPage = () => (
  <main id="not-found-page">
    <div className="py-32 my-32 mx-auto">
      <a href="https://green.bonum.no">
        &larr; Gå tilbake
      </a>

      <div>
        <h1 className="mt-12 mb-20 text-6xl">
          Kunne ikke finne siden
        </h1>

        <div>
          <p>Det ser dessverre ikke ut som vi fant siden du prøvde å nå. Hvis du mener dette er en feil på vårt system, ber vi deg vennligst ta kontakt på <a href="mailto:kontakt@bonum.no" className="underline">kontakt@bonum.no</a>, slik at vi kan søke etter feilen og rette den.</p>
        </div>
      </div>
    </div>
  </main>
)

export default NotFoundPage
